import styled from 'styled-components'

import Logo from 'gatsby-theme-atomic-design/src/atoms/Nav/Logo'

const CustomLogo = styled(Logo)`
  margin: 0;
  width: 128px;
  height: 35px;
`

export default CustomLogo
